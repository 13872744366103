import { mapActions, mapGetters } from "vuex"

export default {
  props: {
    quizId: { type: Number, default: null },
    quizzesAttemptSummaryId: { type: Number, default: null },
  },
  computed: {
    ...mapGetters([
      "quizById",
      "scoreByQuizAndUserId",
      "currentUser",
      "quizInstanceByQuizId2",
      "currentSchoolsUser",
      "allSchoolsUsers",
      "quizzesAttemptSummaryById",
      "scoreById",
    ]),
    quiz() { return this.quizById(this.quizId) },
    quizzesAttemptSummary() { return this.quizzesAttemptSummaryById(this.quizzesAttemptSummaryId) },
    score() {
      if (this.quizzesAttemptSummary) {
        return this.scoreById(this.quizzesAttemptSummary.id)
      } else {
        return this.scoreByQuizAndUserId(this.quiz?.id, this.currentUser.id)
      }
    },
    quizInstance() { return this.quizInstanceByQuizId2(this.quizId, this.currentUser?.id) },
    /**
    * We cannot use "currentSchoolsUser" because it relie on
    * the "last_school_id" of the user which is not set for students.
     **/
    schoolsUser() {
      return this.allSchoolsUsers.find(s => {
        return s.school_id === this.quiz?.school_id
          && s.user_id === this.currentUser?.id
      })
    },

  },
  methods: {
    ...mapActions(["fetchQuizzesSubscriptions", "fetchQuizzesAttemptSummaries"]),
    fetchQuizzesAttemptsSummariesInSameQuiz() {
      return this.fetchQuizzesAttemptSummaries({
        filter: { quiz_id: this.quizId },
      })
    },
  },
}
